import Vue from 'vue'
import { cloneDeep } from "lodash";
import { Component } from "vue-property-decorator";

@Component
export default class ChartsDataConstructor extends Vue {
	sample = { [this.$t('statistics_table_field_title').toString()]: '' }

	getTable(series: any, labels: Array<string>): Array<{[key: string]: string | number}> | null {
		if (series && labels) {
			const objArr: Array<any> = []

			series.forEach((series) => {
				let obj = cloneDeep(this.sample)
				obj[this.$t('statistics_table_field_title').toString()] = series.name

				series.data.forEach((item, index) => {
					const label = labels[index].split(' ')[0]
					obj[label] = item
				})

				objArr.push(obj)
			})
			return objArr
		}
		return null
	}
}
