















































































































import StatisticsRecordsCards from '@/components/StatisticsRecordsCards/StatisticsRecordsCards.vue'
import ChartsDataConstructor from "@/includes/logic/chartsDataConstructor";

import TableExportButton from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'

import Component from 'vue-class-component'
import VueApexCharts from 'vue-apexcharts'
import { Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    VueApexCharts,
    StatisticsRecordsCards,
    TableExportButton,
    LineChart
  }
})
export default class ChannelStat extends Mixins(ChartsDataConstructor) {
  @Prop({ type: Array }) statistics !: any
}
