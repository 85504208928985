var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-statistics"},[_c('page-title'),_c('mobile-landscape-trigger-layout'),_c('div',{staticClass:"channel-statistics__controls w-full flex flex-col lg:flex-row md:justify-end"},[_c('date-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'from',
          'clearable': false
        }
      }}}),_c('date-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'to',
          'clearable': false
        },
      }}}),_c('div',{staticClass:"flex justify-end items-center mt-0 lg:mt-8 ml-0 lg:ml-2 mr-2 lg:mr-0"},[_c('a-button',{attrs:{"type":"primary","icon":"search","disabled":_vm.isButtonDisabled},on:{"click":_vm.getStatistics}})],1)],1),(_vm.statisticsLoaded)?_c('channel-stat',{staticClass:"mt-5",attrs:{"statistics":_vm.metrics}}):_c('a-spin',{staticClass:"w-full"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }