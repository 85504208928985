
































import RecordItem from '@/components/StatisticsRecordsCards/RecordItem/RecordItem.vue'
import { RecordCard } from '@/components/StatisticsRecordsCards/RecordCard.types'

import Vue from 'vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    RecordItem,
    VuePerfectScrollbar
  }
})
export default class StatisticsRecordsCards extends Vue {

  @Prop() statisticsItems !: Array<RecordCard>

  settings = {
    maxScrollbarLength: 60,
    swipeEasing: true,
    useBothWheelAxes: true,
  }
}
