
















































import ChannelStat from '@/components/ChannelStat.vue'
import PageTitle from '@/components/PageTitle.vue'
import ChannelStatisticsMixin from '@/mixins/statistics/Channel/ChannelStatisticsMixin'
import { ChannelService } from '@/includes/services/ChannelService'
import { InputSetups } from '@/mixins/input-setups'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import { errorNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

@Component({
  components: {
    PageTitle,
    ChannelStat,
    MobileLandscapeTriggerLayout
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class ChannelStatistics extends Mixins<InputSetups, UseFields, ChannelStatisticsMixin>(InputSetups, UseFields, ChannelStatisticsMixin) {
  getBoardById!:GetBoardByIdType

  from = moment().add('-31', 'day').format('YYYY-MM-DD')

  to = moment().add('+1', 'day').format('YYYY-MM-DD')

  metrics = {}

  targetsStatistics: Record<number, any> = {}

  get isButtonDisabled(): boolean {
    return !this.from || !this.to || moment(this.from).isAfter(moment(this.to))
  }

  @Watch('$i18n.locale')
  onLocaleChange(): void {
    this.getStatistics()
  }

  getStatistics(): void {
    this.statisticsLoaded = false
    this.loadStatistic()
  }

  async loadStatistic(): Promise<void> {
    try {
      let { data } = await ChannelService.getChannelStat('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        target_id: Number.parseInt(this.$route.params.channelId),
        interval: {
          interval_type: 'Full',
          from: this.from,
          to: this.to
        }
      })

      this.metrics = this.prepareData(cloneDeep(data), this.from, this.to)
    } catch (error: any) {
      errorNotification(error)
    }
  }

  async created(): Promise<void> {
    try {
      await this.$store.dispatch('getStatisticsLocales')
      await this.loadStatistic()
    } catch (error: any) {
      errorNotification(error)
    }
  }
}
