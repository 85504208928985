




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class RecordItem extends Vue {

  @Prop({ type: String, default: 'primary' }) color!: string

  @Prop({ type: [ Number, String ], required: true }) statistic!: number | string

  @Prop({ type: String }) statisticTitle!: string

  @Prop({ type: String }) icon!: string

}
